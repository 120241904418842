import './styles/shapyaFonts.css';
import './styles/shapyaColors.css';
import './styles/ShapyerFormBody.css';

import { Box, Button, Grid, Typography } from '@material-ui/core';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import { Link } from 'gatsby';
import MenuItem from '@material-ui/core/MenuItem';
import ReCAPTCHA from 'react-google-recaptcha';
import React from 'react';
import Select from '@material-ui/core/Select';
import ShapyaSpinner from '../images/shapya_spinner.gif';
import StrDataFormatOne from '../utils/StrDataFormatOne';
import TextField from '@material-ui/core/TextField';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';

const recaptchareq = () => {
  const recaptchares = document.querySelector('#g-recaptcha-response');

  if (recaptchares) {
    recaptchares.setAttribute('required', 'required');
  }
};

const StoreRestaurantFormBodyRightSideForm = () => {
  const { t } = useTranslation();
  const onChangeLanguage = () => { };

  function onChange(value) {
    console.log('Captcha value:', value);
  }

  const [typestore, setTypestore] = React.useState('');

  const handleChange = (event) => {
    setTypestore(event.target.value);
  };

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        'default_service',
        'store_contact_form',
        e.target,
        'user_OWsnyBPupik9scReHvsc5',
      )
      .then(
        (result) => {
          console.log(result.text);
          setTimeout(function () {
            document.getElementById('sending_button').style.display = 'none';
          }, 100);
          setTimeout(function () {
            document.getElementById('formloader').style.display = '';
          }, 100);
          setTimeout(function () {
            document.getElementById('linktm').click();
          }, 3500);
        },
        (err) => {
          throw new Error(err.text);
        },

      );
    e.target.reset();
  }

  return (
    <div>
      <h1
        className="shapyaMediumRed"
        style={{ fontSize: 'calc(28px + 6 * ((100vw - 320px) / 680))' }}
      >
        <b>{t('form.rightsidestores.title')}</b>
      </h1>
      <br />

      <Typography className="shapyaDarkGrey">
        <b>{t('form.rightsidestores.text2')}</b>
      </Typography>
      <br />
      <form onSubmit={sendEmail}>
        <Grid container spacing={2}>
          <Grid item className="migrid" xs={12}>
            <Box m={1} className="mibox" align="left">
              <label class="container">
                &nbsp;{t('form.rightsidestores.checkboxstore')}
                <input
                  type="radio"
                  checked="checked"
                  name="store_store_restaurant"
                  value="store"
                />
                <span class="checkmark"></span>
              </label>
            </Box>
          </Grid>
          <Grid item className="migrid" xs={12} xl={6}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="store_name"
                fullWidth
                required
                autoComplete="none"
                label={t('form.rightsidestores.namelabel')}
                placeholder={t('form.rightsidestores.nameplaceholder')}
                inputProps={{
                  maxLength: 45,
                  pattern: '[^;*=]*',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={12} xl={6}>
            <Box m={1} className="mibox" align="left">
              <InputLabel
                shrink
                id="demo-simple-select-placeholder-label-label"
                fullWidth
                required
              >
                {t('form.rightsidestores.selectlabel')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                name="store_type"
                fullWidth
                autoComplete="none"
                value={typestore}
                onChange={handleChange}
                displayEmpty
                required
              >
                <MenuItem value="" style={{ textAlign: 'left' }} required>
                  {t('form.rightsidestores.selectplaceholder')}
                </MenuItem>
                <MenuItem
                  value={StrDataFormatOne(t('form.rightsidestores.selectgroceries'))}
                >
                  {t('form.rightsidestores.selectgroceries')}
                </MenuItem>
                <MenuItem
                  value={StrDataFormatOne(t('form.rightsidestores.selectbutchershops'))}
                >
                  {t('form.rightsidestores.selectbutchershops')}
                </MenuItem>
                <MenuItem
                  value={StrDataFormatOne(t('form.rightsidestores.selectcandystores'))}
                >
                  {t('form.rightsidestores.selectcandystores')}
                </MenuItem>
                <MenuItem
                  value={StrDataFormatOne(t('form.rightsidestores.selectpharmacies'))}
                >
                  {t('form.rightsidestores.selectpharmacies')}
                </MenuItem>
                <MenuItem
                  value={StrDataFormatOne(t('form.rightsidestores.selectbreweries'))}
                >
                  {t('form.rightsidestores.selectbreweries')}
                </MenuItem>
                <MenuItem
                  value={StrDataFormatOne(t('form.rightsidestores.selectrestaurants'))}
                >
                  {t('form.rightsidestores.selectrestaurants')}
                </MenuItem>
                <MenuItem
                  value={StrDataFormatOne(t('form.rightsidestores.selectbakeries'))}
                >
                  {t('form.rightsidestores.selectbakeries')}
                </MenuItem>
                <MenuItem
                  value={StrDataFormatOne(t('form.rightsidestores.selectflowershops'))}
                >
                  {t('form.rightsidestores.selectflowershops')}
                </MenuItem>
                <MenuItem
                  value={StrDataFormatOne(t('form.rightsidestores.selectfoodtrucks'))}
                >
                  {t('form.rightsidestores.selectfoodtrucks')}
                </MenuItem>
                <MenuItem
                  value={StrDataFormatOne(t('form.rightsidestores.selectothers'))}
                >
                  {t('form.rightsidestores.selectothers')}
                </MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item className="migrid" xs={12}>
            <Box m={1} className="mibox" align="left">
              <FormControl>
                <FormLabel component="label">
                  <small>{t('form.rightsidestores.schedulelabel')}</small>
                </FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="store_schedule_m"
                        value="yes"
                        icon={
                          <Avatar className="MuiAvatar-root MuiAvatar-circular checkOff"
                          >
                            <small>
                              {t('form.rightsidestores.schedulem')}
                            </small>
                          </Avatar>
                        }
                        checkedIcon={
                          <Avatar className="MuiAvatar-root MuiAvatar-circular checkOn"
                          >
                            <small>
                              {t('form.rightsidestores.schedulem')}
                            </small>
                          </Avatar>
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="store_schedule_t"
                        value="yes"
                        icon={
                          <Avatar className="MuiAvatar-root MuiAvatar-circular checkOff"
                          >
                            <small>
                              {t('form.rightsidestores.schedulet')}
                            </small>
                          </Avatar>
                        }
                        checkedIcon={
                          <Avatar className="MuiAvatar-root MuiAvatar-circular checkOn"
                          >
                            <small>
                              {t('form.rightsidestores.schedulet')}
                            </small>
                          </Avatar>
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="store_schedule_w"
                        value="yes"
                        icon={
                          <Avatar className="MuiAvatar-root MuiAvatar-circular checkOff"
                          >
                            <small>
                              {t('form.rightsidestores.schedulew')}
                            </small>
                          </Avatar>
                        }
                        checkedIcon={
                          <Avatar className="MuiAvatar-root MuiAvatar-circular checkOn"
                          >
                            <small>
                              {t('form.rightsidestores.schedulew')}
                            </small>
                          </Avatar>
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="store_schedule_th"
                        value="yes"
                        icon={
                          <Avatar className="MuiAvatar-root MuiAvatar-circular checkOff"
                          >
                            <small>
                              {t('form.rightsidestores.scheduleth')}
                            </small>
                          </Avatar>
                        }
                        checkedIcon={
                          <Avatar className="MuiAvatar-root MuiAvatar-circular checkOn"
                          >
                            <small>
                              {t('form.rightsidestores.scheduleth')}
                            </small>
                          </Avatar>
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="store_schedule_f"
                        value="yes"
                        icon={
                          <Avatar className="MuiAvatar-root MuiAvatar-circular checkOff"
                          >
                            <small>
                              {t('form.rightsidestores.schedulef')}
                            </small>
                          </Avatar>
                        }
                        checkedIcon={
                          <Avatar className="MuiAvatar-root MuiAvatar-circular checkOn"
                          >
                            <small>
                              {t('form.rightsidestores.schedulef')}
                            </small>
                          </Avatar>
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="store_schedule_s"
                        value="yes"
                        icon={
                          <Avatar className="MuiAvatar-root MuiAvatar-circular checkOff"
                          >
                            <small>
                              {t('form.rightsidestores.schedules')}
                            </small>
                          </Avatar>
                        }
                        checkedIcon={
                          <Avatar className="MuiAvatar-root MuiAvatar-circular checkOn"
                          >
                            <small>
                              {t('form.rightsidestores.schedules')}
                            </small>
                          </Avatar>
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="store_schedule_su"
                        value="yes"
                        icon={
                          <Avatar className="MuiAvatar-root MuiAvatar-circular checkOff"
                          >
                            <small>
                              {t('form.rightsidestores.schedulesu')}
                            </small>
                          </Avatar>
                        }
                        checkedIcon={
                          <Avatar className="MuiAvatar-root MuiAvatar-circular checkOn"
                          >
                            <small>
                              {t('form.rightsidestores.schedulesu')}
                            </small>
                          </Avatar>
                        }
                      />
                    }
                  />
                </FormGroup>
              </FormControl>
            </Box>
          </Grid>
          <Grid item className="migrid" xs={6}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="store_opens_at"
                fullWidth
                required
                label={t('form.rightsidestores.opensatlabel')}
                placeholder={t('form.rightsidestores.opensatplaceholder')}
                inputProps={{ maxLength: 45, pattern: '[^;*=]*' }}
                InputLabelProps={{
                  shrink: true,
                }}
                type="time"
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={6}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="store_closes_at"
                fullWidth
                required
                label={t('form.rightsidestores.closesatlabel')}
                placeholder={t('form.rightsidestores.closesatplaceholder')}
                inputProps={{ maxLength: 45, pattern: '[^;*=]*' }}
                InputLabelProps={{
                  shrink: true,
                }}
                type="time"
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={6}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="store_address"
                fullWidth
                required
                autoComplete="off"
                label={t('form.rightsidestores.addresslabel')}
                placeholder={t('form.rightsidestores.addressplaceholder')}
                inputProps={{
                  maxLength: 45,
                  pattern: '[^;*=]*',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={6}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="store_zip_code"
                fullWidth
                required
                autoComplete="none"
                label={t('form.rightsidestores.zipcodelabel')}
                placeholder={t('form.rightsidestores.zipcodeplaceholder')}
                inputProps={{
                  maxLength: 12,
                  pattern: '[^;*=]*',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={6}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="store_city"
                fullWidth
                required
                autoComplete="none"
                label={t('form.rightsidestores.citylabel')}
                placeholder={t('form.rightsidestores.cityplaceholder')}
                inputProps={{
                  maxLength: 35,
                  pattern: '[^;*=]*',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={6}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="store_state"
                fullWidth
                required
                autoComplete="none"
                label={t('form.rightsidestores.statelabel')}
                placeholder={t('form.rightsidestores.stateplaceholder')}
                inputProps={{
                  maxLength: 35,
                  pattern: '[^;*=]*',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={6}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="store_email"
                fullWidth
                required
                autoComplete="none"
                type="email"
                label={t('form.rightside.emaillabel')}
                placeholder={t('form.rightside.emailplaceholder')}
                inputProps={{
                  maxLength: 30,
                  pattern:
                    '[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={6}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="store_phone_number"
                fullWidth
                autoComplete="none"
                required
                label={t('form.rightside.phonenumberlabel')}
                placeholder={t('form.rightside.phonenumberplaceholder')}
                inputProps={{ maxLength: 24, pattern: '[^=?\'"]*' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={12}>
            <Box align="center" mt={2}>
              <ReCAPTCHA
                sitekey="6Lc0WWwaAAAAADbTnntpfueo8PdvKyGpVMqiX4mG"
                onChange={onChange}
                hl="en"
              />
              <Box color="#808080" id="recaptchafield">
                <small>
                  <b>{t('form.rightside.recaptchamsg')}</b>
                </small>
              </Box>
            </Box>
          </Grid>
          <Grid item className="migrid" xs={12}>
            <Box m={1} className="mibox" align="right">
              <Button
                type="submit"
                id="sending_button"
                onClick={recaptchareq}
                style={{
                  fontFamily: 'Montserrat',
                  padding: '5px',
                  margin: '0px',
                  color: '#c43c42',
                  width: 'auto',
                }}
                endIcon={
                  <ArrowForwardIcon
                    style={{ fontSize: '28px', paddingTop: '5px' }}
                  />
                }
              >
                <h3>NEXT </h3>
              </Button>
              <Link to="/thanksMessage/" id="linktm"></Link>
              <p style={{ display: 'none' }} id="formloader">
                <img src={ShapyaSpinner} width="120" />
              </p>
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default StoreRestaurantFormBodyRightSideForm;