import '../i18n';

import Footer from '../components/Footer';
import NavbarWhite from '../components/navbar/NavbarWhite';
import React from 'react';
import SEO from '../components/seo';
import StoreRestaurantFormBody from '../components/StoreRestaurantFormBody';
import { useTranslation } from 'react-i18next';

const StoreRestaurantForm = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <SEO
        title={t('seo.page.storesRestaurantsForm.title')}
        description={t('seo.page.storesRestaurantsForm.description')}
        keywords={[
          'shapya',
          'shopping',
          'delivering',
          'groceries',
          'meals',
          'food truck',
          'stores',
          'restaurants',
        ]}
      />
      <NavbarWhite />
      <StoreRestaurantFormBody />
      <Footer />
    </React.Fragment>
  );
};

export default StoreRestaurantForm;
